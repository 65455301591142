export enum Vertical {
  GENERALIST = 'generalist',
  REALESTATE = 'realestate',
  JOB = 'job',
  CAR = 'car',
}

export enum ParamsType {
  NATIVE_HIDDEN = 'nativeHidden',
  NATIVE_CHECKBOX = 'nativeCheckbox',
  NATIVE_RANGE = 'nativeRange',
  NATIVE_TEXT = 'nativeText',
  CUSTOM_SELECT = 'customSelect',
  CUSTOM_RANGE = 'customRange',
  LOCATION_SELECT = 'locationSelect',
  DISABLED_SELECT = 'disabledSelect',
}

export type VerticalList =
  Vertical.GENERALIST
  | Vertical.JOB
  | Vertical.CAR
  | Vertical.REALESTATE;

export type Addresses = {
  address_id: string,
  address_name: string,
  city: string,
  street: string,
  zipcode: string,
};

export type AvatarImages = {
  extension_name: string,
  id: string,
  mime_type: string,
  name: string,
  url: string,
};

export type Statistics = {
  ads: number,
  views: number,
  inquiries: number,
  favorites: number,
  saved_searches: number,
  unread_messages: number,
};

export type Account = {
  account_id: string,
  account_list_id: string,
  account_type: string,
  addresses: Addresses[],
  avatar_image: AvatarImages,
  created_at: string,
  d2d_disabled: boolean,
  email: string,
  failed_logins: string,
  has_password: boolean,
  is_company: boolean,
  last_login: string,
  marketing_push_subscribe: number,
  message: string,
  name: string,
  newsletter_subscribed: boolean,
  phone: string,
  phone_hidden: boolean,
  region: string,
  statistics: Statistics,
  status_code: number,
  success: boolean,
  valid_phones: { number: string }[],
  zipcode: string,
};

export type Breadcrumb = {
  label: string;
  friendlyUrl: string;
  url: string;
  sq?: {
    cg: string;
    w: number;
  }
};
